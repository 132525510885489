.advanced-search-button-wrapper{
    display: flex !important;
    align-items: flex-end !important;
}

.advanced-search-rule-spacing{
    margin-top: .25rem !important;
}

.advanced-search-rules-selector{
    margin-bottom: .5rem !important;
    padding-bottom: .5rem !important;
    border-bottom: 1px solid lightgrey !important;
}