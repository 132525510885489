.notes-wrapper{
    height: 50vh !important;
    overflow: scroll;
    overflow-x: hidden;
}

.note{
    margin-bottom:.375rem;
    display: flex;
    flex-direction: row;
    border: 1px solid rgb(215, 215, 215);
    border-radius: 0.375rem;
}

.note-details{
    width: 90%;
    padding: .5rem;
}

.note-buttons{
    width: 10%;
}

.allow-white-spacing{
    white-space: pre-line;
}

.width-20{
    width: 20% !important;
}

.margin-top{
    margin-top: 1rem !important;
}