.search-select-row{
    width: 100%;
    padding: .25rem;
    border: 1px solid rgb(212, 212, 212);
    border-radius: 10px;
    --bs-gutter-x: 0 !important;
}

.search-name{
    font-size: 1rem;
    font-weight: 500;
    color: #000;
}

.search-select-button-wrapper{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.search-select-button-wrapper button{
    margin-left: .5rem;
}