.navigation-wrapper{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.no-touch-dropdown a{
    color: black !important;
}

.logged-out-navigation-wrapper{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.menu-items{
    display: flex !important;
    align-items: center !important;
    flex-direction: row !important;
}

.logged-in-user-name{
    display: flex;
    align-items: center;
    padding: .5rem;
    color: rgb(185, 185, 185);
}

.login-menu-item{
    display: flex;
    align-items: center;
}