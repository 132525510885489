.payment-form-input{
    min-width: 125px !important;
    margin-left: 2px !important;
    margin-right: 2px !important;
}

.big-checkbox > input{
    height: 25px !important;
    width: 25px !important;
}

.payment-form{
    display: flex;
    flex-wrap: nowrap;
}
.payment-form > div{
    margin-left: 2px !important;
    margin-right: 2px !important;

}