.form-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: flex-start; */
    flex-wrap: wrap !important;
    margin-bottom: .5rem;
}

.black-text{
    color: black !important;
}

.payment-history-wrapper{
    overflow-x: scroll;
    overflow-y: scroll;
    height: 400px !important;
    width: 100% !important;
}

.individual-payment-wrapper{
    display: flex;
    flex-direction: column;
    min-width: 0;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.175);
    border-radius: 0.375rem;
    padding: 0.375rem;
    width: max-content;
    margin-bottom: .5rem;
}

.red-background{
    background-color: #ff7171 !important;
    color: whitesmoke !important;
}

.red-background:disabled{
    background-color: #ff7171 !important;
    color: whitesmoke !important;
    pointer-events: none;
}

.yellow-background{
    background-color: #fdff71 !important;
    color: black !important;
}

.yellow-background:disabled{
    background-color: #fdff71 !important;
    color: black !important;
    pointer-events: none;
}

.green-background{
    background-color: #34a853 !important;
    color: whitesmoke !important;
}

.form-row input{
    padding: 0.2rem;
    font-weight: 400;
    color: #212529;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
}

.form-control{
    font-size: 14px !important;
}

.contact-section{
    display: flex;
    flex-direction: row;
    margin: .5rem 0;
}

.contact-section > div{
    width: 50vw !important;
}

.form-row label{
    text-align: right;
    padding: 0 .5rem;
    height: 100%;
    width: 80px !important;
}

.tel-wrapper{
    display: flex;
    flex-direction: column;
}

.tel-wrapper > * {
    display: flex;
    flex-direction: row;
}
.tel-wrapper > * > * {
    margin-right: .25rem;
    margin-bottom: .25rem;
}
.claims-search-button{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.form-row .form-check-input[type=checkbox]{
    padding: 0.75rem !important;
    margin-right: 0.375rem;
}

.form-col{
    margin: 1px;
    display: flex;
    flex-direction: row;
    width: 49.5% !important;
}

.form-col input{
    width: 100%;
}

.form-col-small{
    width: 30% !important;
}

.dark-background{
    background-color: gray !important;
    color: whitesmoke !important;
    border-radius: 0.375rem !important;
    padding: .5rem !important;
}

.light-grey-background{
    background-color: lightgray !important;
    border-radius: 0.375rem !important;
    padding: .5rem !important;
}

.black-outline{
    border: 1px solid black;
    border-radius: 0.375rem !important;
    padding: .5rem !important;
}

.split-view-wrapper{
    display: flex;
    flex-direction: row;
}

.split-view-wrapper>div{
    width: 50% !important;
}

.half-width{
    width: 48% !important;
}

.input-small{
    width: 8vw;
}

.full-width{
    width: 100% !important;
}

.adverse-carrier-dropdown > div{
    width: 50% !important;
}

.adverse-carrier-dropdown > label{
    width: 20% !important;
}

.adverse-carrier-dropdown > button{
    margin-left: 1% !important;
    width: 29% !important;
}
@media only screen and (min-width: 1175px){
    .payment-history-wrapper{
        width: 48vw !important;
    }
}

@media only screen and (max-width: 550px){
    .form-col{
        width: 100% !important;
    }
}
@media only screen and (min-width: 870px) and (max-width: 1175px){
    .claim-lookup-wrapper{
        display: flex !important;
        flex-wrap: wrap !important;
    }

    .claim-lookup-wrapper > .col > *{
        width: 100vw !important;
        max-width: 100vw !important;
    }
}

