.search-results-wrapper{
    display: flex;
    flex-direction: row;
    position: -webkit-sticky; /* For Safari */
    position: sticky;
    top: -15px;
    padding-top: 10px;
    padding-bottom: .5rem;
    background-color: white !important;
    z-index: 5;
    width: 100%;
}

.skip-trace-wrapper{
    display: flex !important;
    flex-direction: row !important;
    width: 100% !important;
}

.half-width{
    width: 49.5% !important;
}

.search-results-dropdown{
    width: 50% !important;
    padding-right: .5rem;
    display: flex;
    justify-content: center;
}

.sol-adjustment{
    width: 25% !important;
}

.skip-trace-date-input{
    width: 25% !important;
}

.margin-right{
    margin-right: .25rem;
}

.collector-claim-modal-assign-button{
    padding-left: 2rem !important;
}
