.grid-claim-wrapper button{
    height: 100% !important;
    width: 50% !important ;
    text-align: center;
    background-color: #306b97;
    border-radius: 10px;
    font-weight: bold;
    color: white;
    border: 1px grey;
}

.take-claim-assignment-button{
    font-size: .75rem !important;
}

.grid-claim-wrapper button:hover{
    background-color:#6d9dc2;;
    border: 1px rgb(167, 167, 167);

}

.grid-claim-wrapper p{
    height: 100% !important;
    width: 50% !important;
}

.grid-claim-wrapper{
    display: flex;
    flex-direction: row;
    width: 100% !important;
    height: 100% !important;
}
