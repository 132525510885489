.grid{
    height: 70vh;
    width: 100%;
    margin-top: .5rem;
}

.required-field{
    color: red !important;
}

.height30vh{
    height: 30vh;
}

.header-text{
    width: 100%;
    text-align: center;
}

.padding{
    padding: .5rem
}

.full-width{
    width: 100% !important;
}

.margin{
    margin: 1rem !important;
}

.margin-top{
    margin-top: 1rem !important;
}

.margin-bottom{
    margin-bottom: 1rem !important;
}

.content{
    margin: 0 3rem 0 3rem !important;
}

input:disabled {
    background-color: #e9ecef !important;
    opacity: 1;
}

.contact-wrapper .input-group{
    flex-wrap: nowrap !important;
}

